import '../../../styles/pages/spring-summer-2021/touchdown-on-the-red-planet.scss';
import React from "react";
import Layout from '../../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../../components/spring-summer-2021-stories/more-stories"
import { Parallax } from 'react-parallax';
import Link from "gatsby-plugin-transition-link/AniLink";
import Gallery from "react-photo-gallery";
import TextPDF from '../../../components/spring-summer-2021-stories/text-pdf';

import socialBanner from "../../../images/spring-summer-2021/social-banners/touchdown-on-the-red-planet.jpg";

import HeroImage from "../../../images/spring-summer-2021/touchdown-on-the-red-planet/hero-banner.jpg";
import laurenDucharme from "../../../images/spring-summer-2021/touchdown-on-the-red-planet/Lauren-Ducharme.jpg";

import rover from "../../../images/spring-summer-2021/touchdown-on-the-red-planet/rover.jpg";
import roverHelicopter from "../../../images/spring-summer-2021/touchdown-on-the-red-planet/rover-helicopter.jpg";
import roverLanding from "../../../images/spring-summer-2021/touchdown-on-the-red-planet/rover-landing.jpg";


export default class Index extends React.Component {

  render() {
    var pageTitle = 'Touchdown on the Red Planet';
    var pageDesc = 'Mechanical engineering alumna describes ‘seven minutes of terror’ before historic Mars 2020 landing.';
    var slug = 'touchdown-on-the-red-planet';

    const photos = [
      {
        src: roverLanding,
        width: 1920,
        height: 1080,
        alt: '',
        title: ''
      },
      {
        src: rover,
        width: 1920,
        height: 1080,
        alt: '',
        title: ''
      },
      {
        src: roverHelicopter,
        width: 1920,
        height: 1080,
        alt: '',
        title: ''
      }
    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <TextPDF slug={slug} />
        
        <div className="mars-wrapper">
        <Parallax
          bgImage={HeroImage}
          bgImageAlt="Rendering of space craft approaching Mars"
          strength={200}
          className="mars-hero animated fadeIn slower">

            <div className="mars-hero-text animated fadeInUp slower delay-1s">
              <span className="sub-title">Mechanical engineering alumna describes ‘seven minutes of terror’<br />before historic Mars 2020 landing.</span>
              <h1><span className="title-top-half">Touchdown on the</span> <span className="title-bottom-half">Red Planet</span></h1>
              <p className="byline">By Debra Cano Ramos, Photos courtesy of NASA/JPL-Caltech</p>
            </div>

            <div className="height" />
          </Parallax>
          <article className="wrap small story-content">

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <ul className="pager">
              <li>
                <Link
                cover
                className="pager-link active"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/">1</Link>
              </li>
              <li>
                <Link
                cover
                className="pager-link"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/star-struck/">2</Link>
              </li>
              <li>
                <Link
                cover
                className="pager-link"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/fun-facts/">3</Link>
              </li>
              <li>
                <Link
                cover
                className="pager-link"
                bg="
                url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
                center / cover   /* position / size */
                no-repeat        /* repeat */
                scroll           /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                black            /* color */
                "
                direction="left"
                duration={2}
                to="/spring-summer-2021/touchdown-on-the-red-planet/history-buff-space-career/">4</Link>
              </li>
            </ul>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <figure>
                <img src={laurenDucharme} alt="Lauren Ducharme" />
              </figure>
            </ScrollAnimation>
  
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p><span className="intro-text">As a child, Lauren DuCharme wondered</span> if there is life beyond Earth. She not only reached for the stars, but also helped land a spacecraft on Mars to find out.</p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>The Cal State Fullerton alumna, a systems flight engineer for NASA’s Jet Propulsion Laboratory, is part of the team of engineers, software developers and scientists working on the historic Mars 2020 Perseverance rover mission. The rover is designed to search for signs of past life on the red planet, and collect soil and rock samples that will eventually be returned to Earth.</p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>The rover, affectionately known as “Percy” to her team, touched down safely in Jezero Crater on Feb. 18, following a nearly seven-month, 292.5-million-mile journey to Mars. </p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“When I heard mission control shout out ‘touchdown confirmed!’ I felt this wave of relief and joy, and started bawling,” shares DuCharme, a Class of 2015 mechanical engineering graduate.</p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“I’ve dreamed of landing a spacecraft on another planet since I was a little girl — and I had finally done it. As soon as my husband saw me cheer, he ran into the kitchen to get a bottle of champagne and he let me pop the cork to celebrate.”</p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>The spectacular landing sparked a flood of phone calls, text messages, social media messages and emails from DuCharme’s friends and family congratulating her and the rest of the NASA and Jet Propulsion Laboratory teams. </p>
            </ScrollAnimation>

          </article>
          <ScrollAnimation animateOnce={true} animateIn="fadeInLeft">
            <figure className="full-width gallery">
              <Gallery photos={photos}  />
            </figure>
          </ScrollAnimation>
          <article className="wrap small story-content">
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>DuCharme is a member of the mission’s fault protection system team responsible for developing, testing and validating flight software and hardware, which maintains the health and safety of the rover. </p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>The day before landing, DuCharme, who is working from home due to the COVID-19 pandemic, was on a console used by mission control to monitor different aspects of spaceflight. She closely watched the health status of the spacecraft traveling nearly 12,500 miles per hour. The team was preparing for the spacecraft’s final entry, descent and landing into the Martian atmosphere.</p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“During our overnight shift, we observed the state of the vehicle while the navigation team analyzed the data from the rover. Our job was to determine if we were on a stable trajectory, and whether we needed to make any last-minute changes to ensure a safe landing,” she relays. “I had mixed emotions the entire shift. While I was a little sad because it was my last shift of cruise operations, I was excited for us to land and begin our surface operations.” </p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>DuCharme was most anxious about the harrowing final minutes of the entry, descent and landing from the top of the atmosphere to the surface of Mars, dubbed the “seven minutes of terror.”</p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“Now that I have experienced it firsthand, I can honestly say the ‘seven minutes of terror’ was truly seven minutes of terror. Even before the spacecraft separated from the cruise stage, my heart was racing and I couldn’t sit still. I watched the telemetry from home and listened to JPL mission control commentary. I hoped it was all good news, and it was — a picture-perfect landing.” </p>
            </ScrollAnimation>

            <br /><br />

            <ScrollAnimation animateOnce={true} animateIn="fadeInUp" className="center">
              <Link
              className="button mars-button"
              cover
              bg="
              url(https://news.fullerton.edu/_resources/titanmag/images/spring-summer-2021/mars-hero-banner.jpg)
              center / cover   /* position / size */
              no-repeat        /* repeat */
              scroll           /* attachment */
              padding-box      /* origin */
              content-box      /* clip */
              black            /* color */
              "
              direction="left"
              duration={2}
              to="/spring-summer-2021/touchdown-on-the-red-planet/star-struck/">
                Next
              </Link>
            </ScrollAnimation>

            <br /><br />

  
          </article>

        </div>  
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 