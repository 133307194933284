import React from "react";
import { withPrefix } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link";
import Icon from "../icons"

export default class textPDF extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <TransitionPortal level="top">
        <a href={ withPrefix('/pdf/spring-summer-2021/' + this.props.slug + '.pdf') } id="pdf-text" target="_blank">
          <Icon name="pdf" alt="PDF Icon" />
          Download text alternative PDF
        </a>
      </TransitionPortal>
    );
  }
} 